<script setup lang="ts">
import { Vue3Marquee } from 'vue3-marquee';
import 'vue3-marquee/dist/style.css';
import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from 'tsparticles-engine';

definePageMeta({
  layout: 'blank',
});

const particlesOptions: ISourceOptions = {
  fpsLimit: 60,
  background: {
    color: {
      value: '#050505',
    },
    opacity: 0,
  },
  interactivity: {
    events: {
      resize: true,
    },
    modes: {
      connect: {
        distance: 100,
        radius: 50,
        links: {
          opacity: 0.2,
        },
      },
      grab: {
        distance: 100,
        links: {
          opacity: 0.2,
        },
      },
      bubble: {
        distance: 120,
        duration: 0.4,
        size: 40,
      },
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 1.2,
      },
      remove: {
        quantity: 4,
      },
    },
  },
  particles: {
    color: {
      value: ['#4AC1FC', '#9D47FF', '#F9F7FA'],
    },
    move: {
      enable: true,
      direction: 'outside',
      speed: 0.3,
      random: true,
      straight: true,
      outMode: 'out',
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 50,
    },
    opacity: {
      value: 1,
      random: {
        enable: true,
        minimumValue: 0.1,
      },
    },
    shape: {
      type: 'triangle',
    },
    size: {
      random: {
        enable: true,
        minimumValue: 0.1,
      },
      value: 2,
    },
    rotate: {
      animation: {
        enable: true,
        speed: 5,
        sync: false,
      },
      random: true,
      value: 2,
    },
  },
  detectRetina: true,
};

const particlesInit = async (engine: Engine) => {
  await loadFull(engine);
};

const CHIPS = [
  {
    color: 'violette-5',
    icon: 'shopping-cart',
    text: 'Shop Live',
  },
  {
    color: 'vibrant-pink-5',
    icon: 'gift',
    text: 'Live Giveaways',
  },
  {
    color: 'candy-pink-5',
    icon: 'lightning',
    text: 'Live Drops',
  },
  {
    color: 'sky-5',
    icon: 'gavel',
    text: 'Live Auctions',
  },
];
</script>

<template>
  <div class="w-full h-full xl:flex xl:justify-center xl:items-between">
    <div class="flex flex-col z-10 xl:flex-row justify-center items-between h-auto xl:h-full w-full font-aspekta px-5 xl:px-12 py-5 md:py-10 xl:py-0 gap-10 max-w-7xl">
      <client-only>
        <teleport to="body">
          <Particles id="tsparticles" class="z-0" :particlesInit="particlesInit" :options="particlesOptions" />
        </teleport>
      </client-only>
      <div class="flex-1 xl:basis-2/3 flex flex-col items-center xl:items-start justify-center gap-7 xl:gap-12">
        <div class="flex flex-col gap-4 z-10 xl:gap-5 w-full">
          <div class="flex flex-col gap-5 xl:gap-4 items-center xl:items-start slide-fade-in-from-top--0">
            <LandingLogo/>
            <h1 class="text-4xl md:text-5xl text-center xl:text-left font-extrabold slide-fade-in-from-top--1">The Liveliest Place to Shop</h1>
          </div>
          <div class="hidden xl:flex gap-2 flex-wrap slide-fade-in-from-top--2">
            <LandingChip
                v-for="(chip, index) in CHIPS"
                :key="index"
                :class="index % 2 ? 'md:rotate-2' : 'md:-rotate-2'"
                :color="chip.color"
                :icon="chip.icon"
                :text="chip.text"
              />
          </div>
          <div class="xl:hidden slide-fade-in-from-top--2">
            <Vue3Marquee
              clone
              gradient
              gradient-width="44px"
              :gradient-color="[5,5,5]"
              :duration="20"
            >
              <div class="flex gap-1">
                <LandingChip
                  v-for="(chip, index) in CHIPS"
                  :key="index"
                  :color="chip.color"
                  :icon="chip.icon"
                  :text="chip.text"
                />
              </div>
            </Vue3Marquee>
          </div>
        </div>
        <div class="relative flex flex-col xl:flex-row items-center gap-4 slide-fade-in-from-top--3">
          <div class="hidden xl:block gradient-border absolute w-40 h-40 z-0 blur-1.5xl rounded-xl"/>
          <div class="hidden xl:flex justify-center items-center relative bg-neutral-5/5 rounded-3xl p-2 w-fit overflow-hidden">
            <div class="gradient-border absolute blur-sm w-3/2 h-3/2"/>
            <div class="absolute inset-0.5 z-10 bg-neutral-2 rounded-3xl"/>
            <img src="~~/assets/img/landing-qr.svg" class="w-39 h-39 z-20">
          </div>
          <div class="flex flex-col gap-4 xl:gap-3 justify-center items-center xl:items-start max-w-[250px] text-lg">
            <div class="hidden xl:flex flex-col gap-2">
              <p class="font-medium">Download App</p>
              <p class="text-neutral-4 font-normal leading-5">Scan the QR code on your mobile to download Tilt</p>
            </div>
            <div class="flex gap-2">
              <a href="https://apps.apple.com/gb/app/tilt-shop-with-friends-online/id1588409720" target="_blank">
                <img class="min-w-[150px]" src="~~/assets/img/app-store-link.svg">
              </a>
              <a href="https://play.google.com/store/apps/details?id=retrolabs.retroapp.android" target="_blank">
                <img class="min-w-[170px]" src="~~/assets/img/play-store-link.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="flex gap-3 slide-fade-in-from-top--4">
          <a href="https://seller.tilt.app/" class="slide-fade-in-from-top--4">
            <TiltButton text="Become a seller" color="secondary" />
          </a>
        </div>
      </div>
      <div class="flex-1 xl:basis-1/3 flex z-10 items-center justify-center slide-fade-in-from-top--4">
        <video class="h-[574px] rotate-4 rounded-3xl overflow-hidden border-2 border-neutral-2" src="~~/assets/img/video-seller-1.mp4" muted loop autoplay :controls="false" playsinline></video>
      </div>
      <LandingFooter class="slide-fade-in-from-top--4 z-10"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gradient-border {
  background: conic-gradient(transparent 0deg, #9840FF 30deg, transparent 60deg);
  animation: animatedgradient 6s linear infinite;
}

@keyframes animatedgradient {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>